import CheckBoxRenderer from '../CheckBoxRenderer/CheckBoxRenderer';
import { EXCEL_STYLES_IDS } from '../../../../../common/components/ag-grid';
import { stringToBoolean } from '../../../../utils/helper';

export const attributeMapping = {
  stockCar: 'STOCK_CAR',
  annualTarget: 'ROC'
};

export const createColumnDefs = (productionCorridorData, onChange, tableSpecification) => {
  const modelGroupColumns = productionCorridorData
    .map((item) => ({
      headerName: item.modelGroup,
      headerClass: 'pc-header-cell',
      editable: (params) => params.context.editable,
      menuTabs: [],
      field: item.modelGroup,
      cellClass: ['center-cell', EXCEL_STYLES_IDS.STRING],
      cellClassRules: {
        'ovex-dealer-config-production-corridor-table-modified': (cellParams) => {
          let findElement = productionCorridorData.find((pc) => pc.modelGroup === item.modelGroup)[cellParams.context.attributeName];
          return findElement !== cellParams.value;
        }
      },
      cellRendererFramework: (params) => {
        return CheckBoxRenderer(params, params.context.editable);
      },
      cellRendererParams: {
        onChange: (params, value) => onChange(params, value, item)
      },
      valueSetter: (params) => {
        onChange(params, stringToBoolean(params.newValue), item);
        return true;
      },
      width: 55
    }));
  const description = [{
    menuTabs: [],
    valueGetter: () => tableSpecification,
    width: 290
  }];
  return [...description, ...modelGroupColumns];
};

export function updateCell(gridRef, value, attributeName, modelGroup) {
  const rowNode = gridRef.current.api.getRowNode(attributeName);
  if (rowNode) {
    const updatedRow = { ...rowNode.data };
    updatedRow[modelGroup] = value;
    gridRef.current.api.applyTransaction({
      update: [updatedRow]
    });
  }
}
