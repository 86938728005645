import React, { Fragment } from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import PropTypes from 'prop-types';
import { ActiveDealerBasicInfoDTO } from '@ovex/partner-web-api';
import { DealerWithSpecificConfigurationDTO } from '@ovex/pvt-web-api';

import DealerConfigSpecificationButtonBar
  from '../DealerConfigSpecificationButtonBar/DealerConfigSpecificationButtonBar';
import { OvexAGTable } from '../../../../../common/components/ag-grid';
import ModelGroupListModalForm from '../ModelGroupListModalForm/ModelGroupListModalForm';
import { LsiContext } from '../../../../../common/contexts';
import { useAlertBusOvex, useConfirmModal } from '../../../../../common/hooks';
import { attributeMapping } from '../DealerConfigProductionCorridorTable/DealerConfigProductionCorridorTableData';
import { AlertTypeEnum } from '../../../../../common/objects';
import DealerListModalForm from '../DealerListModalForm/DealerListModalForm';
import { Loading } from '../../../../../common/components';

import {
  createColumnDefs,
  createOrUpdateConfigurations,
  findAndMakeDealerCopy,
  getActualGridData,
  getAddedUpdatedData,
  getAvailableAndSelectedItems,
  getUniqueModelGroups,
  hasAnyDealerEmptySpecificConfigurations,
  isResponseSuccessful,
  mergeDealers,
  simpleDeepCopy,
  sortByDealerName,
  sortByModelGroup,
  updateDealerNameIfNotFound
} from './DealerConfigSpecificationTableData';

const propTypes = {
  activeDealersData: PropTypes.arrayOf(PropTypes.instanceOf(ActiveDealerBasicInfoDTO)),
  attributeName: PropTypes.string.isRequired,
  dealerConfigData: PropTypes.arrayOf(PropTypes.instanceOf(DealerWithSpecificConfigurationDTO)),
  editableProductionCorridor: PropTypes.bool,
  editableSpecification: PropTypes.bool,
  isFetching: PropTypes.bool,
  modelGroupsInProductionCorridor: PropTypes.array,
  onReloadActiveDealers: PropTypes.func,
  onReloadConfigSpecification: PropTypes.func,
  onSetEditableSpecification: PropTypes.func,
  onUpdateConfigSpecification: PropTypes.func,
  pageDescription: PropTypes.shape({
    generalSettingDescription: PropTypes.string,
    legend: PropTypes.string,
    legendDescriptionOff: PropTypes.string,
    legendDescriptionOn: PropTypes.string,
    specificSettingDescription: PropTypes.string,
    tableSpecification: PropTypes.string
  })
};

const defaultProps = {
  activeDealersData: null,
  dealerConfigData: null,
  editableProductionCorridor: false,
  editableSpecification: false,
  isFetching: true,
  modelGroupsInProductionCorridor: [],
  onReloadActiveDealers: undefined,
  onReloadConfigSpecification: undefined,
  onSetEditableSpecification: undefined,
  onUpdateConfigSpecification: undefined,
  pageDescription: PropTypes.shape({
    generalSettingDescription: null,
    legend: null,
    legendDescriptionOff: null,
    legendDescriptionOn: null,
    specificSettingDescription: null,
    tableSpecification: null
  })
};

const DealerConfigSpecificationTable = (props) => {
  const {
    attributeName,
    isFetching,
    editableSpecification,
    editableProductionCorridor,
    dealerConfigData,
    modelGroupsInProductionCorridor
  } = props;
  const {
    onReloadActiveDealers, onUpdateConfigSpecification,
    onReloadConfigSpecification, onSetEditableSpecification
  } = props;

  const lsi = React.useContext(LsiContext);

  const [dealerModalShown, setDealerModalShown] = React.useState(false);
  const [modelGroupModalShown, setModelGroupModalShown] = React.useState(false);

  const [uniqueModelGroups, setUniqueModelGroups] = React.useState([]);
  const [deletedModelGroups, setDeletedModelGroups] = React.useState([]);
  const [deletedDealers, setDeletedDealers] = React.useState([]);
  const [addedModelGroups, setAddedModelGroups] = React.useState([]);
  const [editedModelGroups, setEditedModelGroups] = React.useState({});
  const [rowData, setRowData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  let tableRef = React.useRef(null);

  const { handleAddAlertSimple } = useAlertBusOvex();

  const labelNoData = lsi.getLSIItem(`PVT.LABEL.SPECIFICATION_TABLE_NO_DATA.${attributeMapping[attributeName]}`);
  const tooltipSpecificationNotFound = lsi.getLSIItem(`PVT.TOOLTIP.SPECIFICATION_NOT_FOUND.${attributeMapping[attributeName]}`);

  React.useEffect(() => {
    setRowData(updateDealerNameIfNotFound(dealerConfigData, lsi));
  }, [dealerConfigData]);

  React.useEffect(() => {
    setUniqueModelGroups(sortByModelGroup(modelGroupsInProductionCorridor, getUniqueModelGroups(dealerConfigData)));
  }, [dealerConfigData, modelGroupsInProductionCorridor]);

  React.useEffect(() => {
    if (tableRef.current) {
      rowData.length > 0 && tableRef.current.api.refreshCells({ force: true });
      rowData.length > 0 && tableRef.current.api.refreshHeader();
    }
  }, [editableSpecification]);

  const showTable = React.useMemo(() => {
    return !!(rowData && rowData.length > 0);
  }, [rowData]);

  const showEmptyData = React.useMemo(() => {
    return !!((!rowData || rowData.length === 0) && !isFetching);
  }, [rowData, isFetching]);

  const handleDealerModal = () => {
    if (dealerModalShown) {
      const { activeDealersData } = props;
      const { availableItems, selectedItems } = getAvailableAndSelectedItems(activeDealersData, rowData);
      return (
        <DealerListModalForm
          items={availableItems}
          onClose={handleCloseDealerEditModal}
          onSave={handleSaveEditingDealers}
          shown={dealerModalShown}
          values={selectedItems}
        />
      );
    }
  };

  const handleModelGroupModal = () => {
    if (modelGroupModalShown) {
      return (
        <ModelGroupListModalForm
          modelGroupsInProductionCorridor={modelGroupsInProductionCorridor}
          modelGroupsSpecificConfig={uniqueModelGroups}
          onClose={handleCloseModelGroupEditModal}
          onSave={handleSaveEditingModelGroups}
          shown={modelGroupModalShown}
        />
      );
    }
  };

  const handleOpenDealerEditModal = () => {
    onReloadActiveDealers();
    setDealerModalShown(true);
  };

  const handleCloseDealerEditModal = () => {
    setDealerModalShown(false);
  };

  const handleOpenModelGroupEditModal = () => {
    setModelGroupModalShown(true);
  };
  const handleCloseModelGroupEditModal = () => {
    setModelGroupModalShown(false);
  };
  const handleSetEditableSpecification = (value) => {
    onSetEditableSpecification(value);
  };

  const handleSaveEditingModelGroups = React.useCallback(({ values, addedModelGroups, deletedModelGroups }) => {
    if (values.length === 0 && deletedModelGroups.length > 0) {
      const allItemsDeleted = deletedModelGroups.every((value) => uniqueModelGroups.includes(value));
      if (allItemsDeleted) {
        setEditedModelGroups({ deletedModelGroups, values, addedModelGroups });
        handleConfirmAction(true);
        return;
      }
    }
    setEditingModelGroupResults(deletedModelGroups, values, addedModelGroups);
  }, [handleConfirmAction, uniqueModelGroups]);

  const handleSaveEditingDealers = React.useCallback(({ values, addedDealers, deletedDealers }) => {
    setDeletedDealers((prevDealers) => {
      const filteredDealers = prevDealers.filter((group) => !addedDealers.includes(Number(group.dealerNumber)));
      return [...filteredDealers, ...deletedDealers];
    });

    const dealerMapActual = new Map(getActualGridData(tableRef).map((dealer) => [dealer.dealerNumber, dealer]));
    const dealerMapOriginal = new Map(dealerConfigData.map((dealer) => [dealer.dealerNumber, simpleDeepCopy(dealer)]));

    const updatedDealers = mergeDealers(values, dealerMapActual, dealerMapOriginal, uniqueModelGroups)
      .sort(sortByDealerName);

    if (!values.length) {
      setDeletedModelGroups(uniqueModelGroups);
      setUniqueModelGroups([]);
    }

    setRowData(updatedDealers);
    setDealerModalShown(false);
  }, [rowData, uniqueModelGroups]);

  const handleSaveWithoutModelGroups = React.useCallback(async () => {
    setEditingModelGroupResults(editedModelGroups.deletedModelGroups, editedModelGroups.values, editedModelGroups.addedModelGroups);
    const allDealers = getActualGridData(tableRef);
    setDeletedDealers(allDealers);
    setRowData([]);
    setUniqueModelGroups([]);
    setModelGroupModalShown(false);
  }, [editedModelGroups]);

  const [renderConfirmModal, handleConfirmAction] = useConfirmModal(
    handleSaveWithoutModelGroups,
    {
      content: lsi.getLSIItem('PVT.CONFIRM_MESSAGE.DEALER_DELETE')
    }
  );

  const setEditingModelGroupResults = (deletedModelGroupsParam, values, addedModelGroupsParam) => {
    const updatedSpecification = getActualGridData(tableRef).map((dealer) => {
      const dealerCopy = findAndMakeDealerCopy(dealer.dealerId, dealerConfigData);
      return {
        ...dealer,
        specificConfigurations: createOrUpdateConfigurations(dealer, values, addedModelGroupsParam, dealerCopy)
      };
    });

    const uniqueModelGroupsOriginalData = getUniqueModelGroups(dealerConfigData);

    setDeletedModelGroups(prevModelGroups => [
      ...prevModelGroups.filter(group => !addedModelGroupsParam.includes(group)),
      ...deletedModelGroupsParam
    ]);

    const filterGroups = (groups) => groups.filter((group) => !uniqueModelGroupsOriginalData.includes(group));
    setAddedModelGroups((prevAddedModelGroups) => {
      const filteredCurrentGroups = filterGroups(addedModelGroupsParam);
      const filteredPrevGroups = filterGroups(prevAddedModelGroups);
      return [...filteredCurrentGroups, ...filteredPrevGroups];
    });
    setRowData(updatedSpecification);
    setUniqueModelGroups(sortByModelGroup(modelGroupsInProductionCorridor, values));
    setModelGroupModalShown(false);
  };
  const resetStates = () => {
    setDeletedModelGroups([]);
    setDeletedDealers([]);
    setAddedModelGroups([]);
    setRowData([]);
    handleSetEditableSpecification(false);
  };

  const handleSaveDealerConfig = async () => {
    if (!tableRef.current) {
      resetStates();
      return;
    }

    let rowDataGrid = getActualGridData(tableRef);
    const hasEmptySpecificConfigurations = hasAnyDealerEmptySpecificConfigurations(rowDataGrid);
    if (hasEmptySpecificConfigurations) {
      handleAddAlertSimple('PVT.ERROR_MESSAGE.UPDATE_SPECIFIC_CONFIG_WITHOUT_MODEL_GROUP', AlertTypeEnum.WARNING);
      return;
    }

    const createdOrUpdatedSpecificConfigurations = getAddedUpdatedData(rowDataGrid);
    if (createdOrUpdatedSpecificConfigurations.length > 0 || deletedModelGroups.length > 0 || deletedDealers.length > 0) {
      setLoading(true);
      const response = await onUpdateConfigSpecification({
        createdOrUpdatedSpecificConfigurations: createdOrUpdatedSpecificConfigurations,
        deletedModelGroups: deletedModelGroups,
        deletedDealerIds: deletedDealers.map((dealer) => dealer.dealerId)
      });
      isResponseSuccessful(response) && resetStates();
      response && setLoading(false);
    } else {
      handleSetEditableSpecification(false);
    }
  };

  const handleReloadConfigSpecification = () => {
    onReloadConfigSpecification();
    resetStates();
  };

  const handleCancelEdit = () => {
    const createdOrUpdatedSpecificConfigurations = getAddedUpdatedData(getActualGridData(tableRef));
    if (createdOrUpdatedSpecificConfigurations.length > 0 || deletedDealers.length > 0 || deletedModelGroups.length > 0) {
      setRowData(dealerConfigData);
      setUniqueModelGroups(sortByModelGroup(modelGroupsInProductionCorridor, getUniqueModelGroups(dealerConfigData)));
      tableRef.current.api.setRowData(dealerConfigData);
    }
  };

  const columnDefs = React.useMemo(() => {
    return createColumnDefs(lsi, uniqueModelGroups, setRowData, tooltipSpecificationNotFound, tableRef);
  }, [lsi, uniqueModelGroups]);

  const handleRefTable = (refTable) => {
    tableRef.current = refTable;
  };

  return (
    <Fragment>
      {loading && <Loading loading={loading}/>}
      <DealerConfigSpecificationButtonBar
        disabledModelGroup={rowData && rowData.length === 0}
        editableProductionCorridor={editableProductionCorridor}
        editableSpecification={editableSpecification}
        onCancel={handleCancelEdit}
        onReloadConfigSpecification={handleReloadConfigSpecification}
        onSave={handleSaveDealerConfig}
        onSetDealerModalShown={handleOpenDealerEditModal}
        onSetEditableSpecification={handleSetEditableSpecification}
        onSetModelGroupModalShown={handleOpenModelGroupEditModal}
        pageDescription={props.pageDescription}
      />
      {showTable &&
        <OvexAGTable
          agContext={{
            editable: editableSpecification,
            addedModelGroups: addedModelGroups
          }}
          className={'ovex-dealer-config-specification-table'}
          columnDefs={columnDefs}
          enableFillHandle
          enableRangeSelection
          getRowId={(params) => {
            return params.dealerNumber;
          }}
          onGridReady={handleRefTable}
          rowData={rowData}
          suppressClickEdit
        />}
      {showEmptyData && <Bricks.Text>{labelNoData}</Bricks.Text>}
      {handleDealerModal()}
      {handleModelGroupModal()}
      {renderConfirmModal()}
    </Fragment>
  );

};
DealerConfigSpecificationTable.defaultProps = defaultProps;
DealerConfigSpecificationTable.propTypes = propTypes;
export default DealerConfigSpecificationTable;