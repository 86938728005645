import { DealerStatusEnum as DealerStatusEnumClass, DealerWithSpecificConfigurationUpdateDTO } from '@ovex/pvt-web-api';

import CheckBoxRenderer from '../CheckBoxRenderer/CheckBoxRenderer';
import DealerRenderer from '../DealerRenderer/DealerRenderer';
import './DealerConfigSpecificationTable.scss';
import { EXCEL_STYLES_IDS } from '../../../../../common/components/ag-grid';
import { stringToBoolean } from '../../../../utils/helper';

export const DEALER_STATUS = new DealerStatusEnumClass();


export const createColumnDefs = (lsi, uniqueModelGroups, setRowData, tooltipSpecificationNotFound, tableRef) => {
  const baseColumns = [
    {
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.DEALER'),
      field: 'dealerName',
      cellRendererFramework: (params) => DealerRenderer(lsi, params),
      cellClass: 'specification-label-cell',
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      suppressMovable: true
    },
    {
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.NUMBER'),
      field: 'dealerNumber',
      cellClass: 'specification-label-cell',
      cellClassRules: {
        'dealer-name-not-active': params => {
          return isDealerNotActive(params.data);
        },
        'ovex-dealer-renderer-text-new-dealer': params => {
          return isDealerNew(params.data);
        }
      },
      width: 90,
      filter: true,
      sortable: true,
      suppressMovable: true
    }
  ];
  const dynamicColumns = uniqueModelGroups.map((modelGroup) => ({
    colId: modelGroup,
    headerName: modelGroup,
    field: modelGroup,
    editable: (params) => {
      return params.context.editable;
    },
    cellRendererFramework: (params) => {
      return CheckBoxRenderer(params, params.context.editable, tooltipSpecificationNotFound);
    },
    valueSetter: (params) => {
      const rowNode = tableRef.current.api.getRowNode(params.data.dealerNumber);
      if (rowNode) {
        const updatedRow = { ...rowNode.data };
        updatedRow.specificConfigurations = updatedRow.specificConfigurations.map((config) =>
          config.modelGroup === modelGroup ? {
            ...config,
            activated: stringToBoolean(params.newValue),
            modified: params.newValue !== params.oldValue ? !config.modified : config.modified
          } : config
        );
        tableRef.current.api.applyTransaction({
          update: [updatedRow]
        });
      }
      return true;
    },
    valueGetter: params => {
      let find = params.data.specificConfigurations.find(item => item.modelGroup === modelGroup);
      return find?.activated;
    },
    headerClass: (headerClassParams) => {
      let headerClass = ['specification-header-cell'];
      if (headerClassParams.context.addedModelGroups.includes(modelGroup)) {
        headerClass.push('specification-header-cell-new-model-group');
      }
      return headerClass.join(' ');
    },
    cellClass: ['center-cell', EXCEL_STYLES_IDS.STRING],
    width: 55,
    menuTabs: [],
    cellRendererParams: {
      onChange: (params, value) => {
        const rowNode = tableRef.current.api.getRowNode(params.data.dealerNumber);
        if (rowNode) {
          const updatedRow = { ...rowNode.data };
          updatedRow.specificConfigurations = updatedRow.specificConfigurations.map((config) =>
            config.modelGroup === modelGroup ? { ...config, activated: value, modified: !config.modified } : config
          );
          tableRef.current.api.applyTransaction({
            update: [updatedRow]
          });
        }
      }
    }
  }));
  return [...baseColumns, ...dynamicColumns];
};

export const mergeDealers = (values, dealerMapActual, dealerMapOriginal, uniqueModelGroups) => {
  return values.map(({ dealerNumber, dealerName }) => {
    let updatedDealer = dealerMapActual.get(dealerNumber) || dealerMapOriginal.get(dealerNumber) || createNewDealer(dealerName, dealerNumber);
    updatedDealer.specificConfigurations = uniqueModelGroups.map((mg) => {
      let config = updatedDealer.specificConfigurations.find((config) => config.modelGroup === mg);
      if (!config) {
        config = createDefaultConfig(mg);
      }
      return config;
    });
    return updatedDealer;
  });
};

export const getAddedUpdatedData = (data) => {
  return data.map(dealer => {
    const nd = {
      ...dealer,
      specificConfigurations: dealer.specificConfigurations.filter(config => isConfigNewOrModified(config))
    };
    return DealerWithSpecificConfigurationUpdateDTO.constructFromObject(nd);
  }).filter(dealer => dealer.specificConfigurations.length > 0);
};

export const getAvailableAndSelectedItems = (activeDealersData, rowData) => {
  const availableItems = (activeDealersData || []).map(item => {
    const dealer = rowData.find(dealer => dealer.dealerNumber === item.dealerNumber);
    return {
      dealerId: dealer?.dealerId || null,
      value: item.dealerNumber,
      dealerNumber: item.dealerNumber,
      dealerName: dealer ? dealer.dealerName : item.dealerName
    };
  });

  rowData.forEach(dealer => {
    let find = availableItems.find(innerDealer => innerDealer.dealerNumber === dealer.dealerNumber);
    if (!find) {
      availableItems.push({
        dealerId: dealer?.dealerId || null,
        value: dealer.dealerNumber,
        dealerNumber: dealer.dealerNumber,
        dealerName: dealer.dealerName
      });
    }
  });

  const selectedItems = rowData.map(item => item.dealerNumber);

  availableItems.sort(sortByDealerName);

  return { availableItems, selectedItems };
};

export const getActualGridData = (gridRef, transformFunc) => {
  let rowDataGrid = [];
  if (gridRef.current) {
    gridRef.current.api.forEachNode(node => {
      rowDataGrid.push(transformFunc ? transformFunc(node.data) : node.data);
    });
  }
  return rowDataGrid;
};

export const updateDealerNameIfNotFound = (dealers, lsi) => {
  return dealers?.map(dealer => {
    const updatedDealer = dealer.status === DEALER_STATUS.NOT_FOUND ?
      { ...dealer, dealerName: lsi.getLSIItem('PVT.LABEL.DEALER_NOT_FOUND') } : { ...dealer };
    const updatedConfigurations = updatedDealer.specificConfigurations?.map(config => ({
      ...config,
      modified: false
    }));
    return {
      ...updatedDealer,
      specificConfigurations: updatedConfigurations
    };
  }).sort(sortByDealerName);
};

export const createNewDealer = (dealerName, dealerNumber) => ({
  dealerId: null,
  dealerName,
  dealerNumber,
  specificConfigurations: []
});

export const createOrUpdateConfigurations = (dealer, values, addedModelGroups, dealerConfigData) => {
  return values
    .map((group) => {
      if (addedModelGroups.includes(group)) {
        let find = dealerConfigData?.specificConfigurations.find(({ modelGroup }) => modelGroup === group);
        return { id: find?.id || null, modelGroup: group, activated: find?.activated || false, modified: false };
      }
      return dealer.specificConfigurations.find(({ modelGroup }) => modelGroup === group);
    })
    .filter((config) => config !== undefined);
};

export const getUniqueModelGroups = (dealerConfigData) => {
  if (dealerConfigData && dealerConfigData.length > 0) {
    const uniqueModelGroupsInConfig = new Set(dealerConfigData.flatMap(dealer =>
      dealer.specificConfigurations.map(config => config.modelGroup))
    );
    return Array.from(uniqueModelGroupsInConfig);
  }
  return [];
};

export const sortByModelGroup = (sortOrder, data) => {
  const modelGroupOrder = sortOrder.map(item => item.modelGroup);

  return data.sort((a, b) => {
    const indexA = modelGroupOrder.indexOf(a);
    const indexB = modelGroupOrder.indexOf(b);

    if (indexA === -1 && indexB === -1) {
      return 0;
    }

    return indexA - indexB;
  });
};

export const createDefaultConfig = (modelGroup) => ({
  id: null,
  modelGroup,
  activated: false,
  modified: false
});

export const findAndMakeDealerCopy = (dealerId, dealerConfigData) => {
  const dealer = dealerConfigData.find((d) => d.dealerId === dealerId);
  return dealer ? { ...dealer } : null;
};

export const sortByDealerName = (a, b) => (a.dealerName || '').localeCompare(b.dealerName || '');

export const hasAnyDealerEmptySpecificConfigurations = (data) =>
  data.some((dealer) => dealer.specificConfigurations.length === 0);

const isConfigNewOrModified = (config) => {
  return config.modified === true || config.id === null;
};


export const simpleDeepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const isResponseSuccessful = (response) => {
  return response?.response?.ok;
};

const isDealerNew = (data) => {
  return data.dealerId === null;
};

const isDealerNotActive = (data) => {
  return data.status === DEALER_STATUS.NOT_FOUND ||
    data.status === DEALER_STATUS.ARCHIVED;
};

